import React from 'react'
import InputField from '../components/InputField';
import '../styles.css';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import BottomMatter from '../components/BottomMatter';
import { ToastContainer } from 'react-toastify';
import Globe from '../resources/globe.png';
import Hat from '../resources/dochat.png';

export default function Education() {

  return (
    <div className='absolute flex flex-col w-screen h-screen bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/Education$"}/>
      <div className='absolute w-screen h-1/2 translate-y-1/2 grid grid-cols-1 gap-4 lg:grid-cols-3 place-items-center mb-12'>
        <img className='w-64 h-64' src={Globe} alt=""/>
        <div className="w-full max-w-2xl p-4 border border-gray-200 rounded-lg shadow sm:p-8 bg-gray-900">
          <div className="flow-root">
                <ul className="divide-y divide-gray-700">
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl truncate text-white">
                                    Doctor of Philosophy
                                </p>
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl text-blue-300 truncate ">
                                    Queensland University of Technology
                                </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-white">
                                2022 - now
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">

                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl truncate text-white">
                                   Bachelor of Mathematics (Statistics)
                                </p>
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl text-blue-300 truncate ">
                                   Queensland University of Technology
                                </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-white">
                                2018-2021
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl  truncate text-white">
                                    Bachelor of Info. Tech. (Computer Science)
                                </p>
                                <p className="text-sm font-medium sm:text-md md:text-lg lg:text-xl xl:text-2xl text-blue-300 truncate ">
                                    Queensland University of Technology
                                </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-white">
                                2018-2021
                            </div>
                        </div>
                    </li>
                </ul>
          </div>
        </div>
        <img className='w-64 h-64' src={Hat} alt=""/>
      </div>
      <BottomMatter arrowNeeded={false}/>
      <ToastContainer/>
    </div>
  )
}

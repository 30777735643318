import React from 'react';
import '../styles.css';


function Tag({id, words, clickFunc}) {
  return (
  <div id={id? id : ""} onClick={clickFunc? clickFunc: null} className="hover:cursor-pointer px-3 py-2 text-xs font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">{words}</div>
  )
}

export default Tag
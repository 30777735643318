import {Suspense} from 'react';
import { Canvas } from '@react-three/fiber';
import '../styles.css';
import Show from './Show';


export default function MainScene() {
  return (
    <Suspense fallback={null}>
      <Canvas className='flex flex-col relative w-screen h-screen flex-grow' shadows>
        <Show/>
      </Canvas>
    </Suspense>
  )
}

import { extend } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import myFont from '../resources/Bungee_Regular.json'

extend({ TextGeometry })

export default function Name({text, position, colour, size, thickness}) {
    const font = new FontLoader().parse(myFont);

    return(
        <mesh position={position} rotation-y={Math.PI / 4}>
            <textGeometry args={[text, {font, size:size, height: thickness}]}/>
            <meshLambertMaterial attach='material' color={colour}/>
        </mesh>
    )
}
import InputField from '../components/InputField';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import BottomMatter from '../components/BottomMatter';
import { ToastContainer } from 'react-toastify';
import Coffee from '../resources/Coffee.png';
import Duck from '../resources/Duck.png';

export default function Experience() {

  const site_de_job = 
  "Established the company's software development team and assisted in creating its SaaS capabilities. \r\nDeveloped applications for the web and desktop, utilising a wide software stack from React.js, Python, C++, SQL and many others. \r\nCreated a proof of concept for many internal and external software including Cadastral Gazeteer, Digital Site Insruction Docket, ADAC XML Viewer, and Survey Plan Comparator.\r\n Developed high-performance parallel computing applications using CUDA for model detection and point cloud segmentation and classification."

  const sra_job = 
  "Lead the Services Availability QLD project through the entire software lifecycle, including planning, designing, developing, deploying and maintaining the product.\r\nThe deliverable is a React.js web application integrated with with Firebase and ArcGIS SDK JS. I have also conducted extensive statistical analysis and modelling via geographically weighted regression to assess people's access to crucial services. These findings are directly supported by the delivered software solution."
  
  const sra_job_2 =
  "Explored the spatial optimisation and partitioning / regionalisation problems through clustering, hierarchical aggregation and graph-based algorithms, as well as methods of geospatial interpolation such as Empirical Bayesian Kriging, aerial interpolation, and others."

  const gpone_job =
  "Conducted spatial data analysis in ArcGIS Pro. Analysed geospatial data from a PostgreSQL database on AWS EC2 instance. Implemented custom ArcGIS Pro toolboxes in Python and C#. Designed, implemented and maintained machine learning solutions in Python (object detection). \r\nEvaluated model performance and implemented performance metrics solutions. Designed and developed data augmentation scripts, and conducted data cleaning. Constructed ArcGIS Dashboards, performed data analysis and data visualisation.\r\nImplemented Survey123 solutions. Designed wireframes and mobile application UI / UX in Figma."
  
  const motorsports =
  "Designed, developed and deployed web applications and databases using React.js and MySQL. Worked on Auxiliary software to support the Formula SAE team. Optimised and maintained established internal software."
  
  const undergrad =
  "Performed spatio-temporal correlation analysis and statistical Fourier analysis of COVID-19 cases versus the UV index. Maintained and cleaned datasets from external sources.\r\nPlanned and constructed experimental designs. Interpreted, analysed and modelled datasets using R and Python."

  const ezymaths = 
  "Taught QCAA approved syllabus for Mathematics and IT. Written lesson plans and scheduled tutoring sessions. Monitored student performance and reviewed class material.\r\nCompleted personal development modules to improve tutoring techniques.\r\nCommunicated with the management and guardians to create better study environment for the students"

  const csharp = 
  "Provided academic support to undergraduate students of Computer Science / Software Engineering. Written lesson plans. \r\nReviewed lecture / workshop material for CAB 201 Programming Principles to facilitate student success.\r\nCommunicated with the Senior Tutor and academic staff regarding student progress."
  
  const itofficer =
  "Troubleshooting of both personal devices and College IT Systems. Provided IT support to a large client base of up to 250 students. Acted as the on-site contact for IT related issues."

  const pattydesc = 
  "Taught all levels of QCAA approved Senior Mathematics and Digital Solutions. Facilitated weekly tutorial sessions to provide additional student support.\r\nAssisted in content delivery. Provided in-class support to students in classes of up to 20 students."

  return (
    <div className='absolute flex flex-col w-screen h-screen bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/Experience$"}/>
      <div className='w-screen h-full place-items-center grid grid-cols-1 lg:grid-cols-3'>
        <div className='hidden lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center'>
          <img className='hidden lg:w-64 lg:h-64 lg:flex' src={Duck} alt=""/>
        </div>
        <div className='w-full px-10 h-full flex items-start justify-center mt-64 sm:mt-48 py-10 mb-32 overflow-auto'>
          <ol className="relative border-l border-gray-200 dark:border-gray-700">                  
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-white">2022/23</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Data Scientist</h3>
              <span className="mb-4 text-base font-bold text-green-600">SiteDE</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{site_de_job}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2023</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Senior Research Assistant</h3>
              <span className="mb-4 text-base font-bold text-green-600">Queensland University of Technology</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{sra_job}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2022</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Senior Research Assistant</h3>
              <span className="mb-4 text-base font-bold text-green-600">Queensland University of Technology</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{sra_job_2}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2021/22</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Data Scientist</h3>
              <span className="mb-4 text-base font-bold text-green-600">GP One Consulting</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{gpone_job}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2021</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Software Developer</h3>
              <span className="mb-4 text-base font-bold text-green-600">QUT Motorsports</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{motorsports}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2020</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Undergraduate Researcher</h3>
              <span className="mb-4 text-base font-bold text-green-600">Queensland University of Technology</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{undergrad}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2020/21</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Mathematics Tutor</h3>
              <span className="mb-4 text-base font-bold text-green-600">Ezy Math Tutoring</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{ezymaths}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2020</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Programming Tutor</h3>
              <span className="mb-4 text-base font-bold text-green-600">Cromwell College UQ</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{csharp}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">January 2020</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">Mathematics Tutor (Volunteer)</h3>
              <span className="mb-4 text-base font-bold text-green-600">St Patrick's Senior College</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{pattydesc}</p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm lg:text-md xl:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">2019/20</time>
              <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-white">IT Support Officer (Volunteer)</h3>
              <span className="mb-4 text-base font-bold text-green-600">Cromwell College UQ</span>
              <p className="mb-4 w-full text-base font-normal  text-gray-500 dark:text-gray-400">{itofficer}</p>
            </li>
          </ol>
        </div>

        <div className='hidden lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center'>
          <img className='hidden lg:w-64 lg:h-64 lg:flex' src={Coffee} alt=""/>
        </div>
      </div>

      <BottomMatter arrowNeeded={true}/>
      <ToastContainer/>
    </div>
  )
}

import {AiFillQuestionCircle} from 'react-icons/ai';
import { toast } from 'react-toastify';
import Arrow from './Arrow';

export default function BottomMatter({arrowNeeded}) {

    const year = new Date().getFullYear()

    const whatToDo = () => toast.info('Navigate as in a Linux Terminal. Type help for hints.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        
    return (
    <footer className="mt-4 fixed bottom-0 left-0 z-20 w-screen p-5 md:flex md:items-center md:justify-between bg-black border-t border-white border-opacity-50">
        <span className="text-sm text-white sm:text-center">© {year}, Phil Juricev. All Rights Reserved.
        </span>
        {arrowNeeded && <Arrow/>}
            <AiFillQuestionCircle className='flex h-10 w-10 items-center justify-center text-lg font-bold bg-blue-500 absolute bottom-4 right-4 rounded-full z-50 hover:brightness-150 hover:cursor-pointer' onClick={whatToDo}/>
    </footer>
    )
}

import React from "react";
import { useLoader } from "@react-three/fiber";
import { MeshReflectorMaterial} from "@react-three/drei";
import {  TextureLoader } from "three";

export function Ground() {

  const [roughness, normal] = useLoader(TextureLoader, [
    process.env.PUBLIC_URL + "terrain-roughness.jpg",
    process.env.PUBLIC_URL + "terrain-normal.jpg",
  ]);


  return (
    <mesh rotation-x={-Math.PI * 0.5} castShadow receiveShadow>
      <planeGeometry args={[80, 80]} />
      <MeshReflectorMaterial
        envMapIntensity={0}
        normalMap={normal}
        normalScale={[0.15, 0.15]}
        roughnessMap={roughness}
        dithering={true}
        color={[0.015, 0.015, 0.015]}
        roughness={0.7}
        blur={[1000, 400]}
        mixBlur={30} 
        mixStrength={80} 
        mixContrast={1} 
        resolution={1024} 
        mirror={0} 
        depthScale={0.01}
        minDepthThreshold={0.9} 
        maxDepthThreshold={1} 
        depthToBlurRatioBias={0.25} 
        debug={0}
        reflectorOffset={0.2} />
    </mesh>
  );
}
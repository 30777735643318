import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import About from './pages/About';
import Education from './pages/Education';
import Experience from './pages/Experience';
import NoPage from './pages/NoPage';
import Projects from './pages/Projects';
import Publications from './pages/Publications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App />}/>
    <Route path="/projects" element={<Projects/>} />
    <Route path="/experience" element={<Experience/>}/>
    <Route path="/education" element={<Education/>}/>
    <Route path="/publications" element={<Publications/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="*" element={<NoPage />} />
  </Routes>
</BrowserRouter>
);

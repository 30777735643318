import React, {useRef} from 'react';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from '@react-three/fiber'

export default function JiggerModel(props) {

    const group = useRef();
    const gltf = useLoader(GLTFLoader, process.env.PUBLIC_URL + "tripodMesh.gltf");
    
    return (
      <group ref={group} {...props} dispose={null}>
        <primitive object={gltf.scene} />
      </group>
    );

}

import '../styles.css';
import Tag from '../components/Tag';
import Token1 from '../resources/abacus.png';
import Token2 from '../resources/camera.png';
import Token3 from '../resources/compass.png';
import Token4 from '../resources/donut.png';
import Token5 from '../resources/fields.png';
import Token6 from '../resources/floppy.png';
import Token7 from '../resources/saber.png';

function ProjectCard({name, isFlipped, tagsList, image, description}) {

    const tokens = [Token1, Token2, Token3, Token4, Token5, Token6, Token7];
    const jokes = [
        "What is a better name for Frontend Developers? <div>elopers",
        "I rate my programming a solid C++",
        "What is a programmer’s favorite horror movie? The XORcist",
        "Why aren't frontend developers humble? They display: flex;",
        "A new database query walks into a bar. The server says, \'Sorry, cache only.\'",
        "Yesterday, I met someone from Australia who works in IT. I said, \'Do you come from a LAN Down Under?'",
        "Unix is user-friendly. It's just picky about who its friends are.",
        "Why do programmers always get Christmas and Halloween mixed up? Because DEC 25 = OCT 31",
        "Two bytes meet. The first byte asks, \'Are you ill?\' The second byte replies, \'No, just feeling a bit off\'.",
        "The generation of random numbers is too important to be left to chance."
    ]

    return(
        isFlipped ? (
        <div className="hover:bg-gray-100 hover:bg-opacity-25 py-4 h-full w-full bg-transparent flex flex-col items-center justify-between border-l border-t border-solid border-gray-400">
            <img className='object-contain w-48 h-48 mb-4' src={image} alt='' />
            <div className="lg:px-4 sm:flex sm:flex-col items-center justify-evenly">
                <div className="px-2 ml-2 font-bold text-xl text-white mb-2">{name}</div>
                <p className="px-2 ml-2 text-white text-base w-3/4">
                    {description}
                </p>
            </div>
            <div className="w-full px-6 pt-4 pb-2 flex flex-wrap gap-2 justify-left">
                {tagsList.map((tag, index) => (
                    <Tag key={index} words={tag} />
                ))}
        </div>
      </div>) : (
        <div className="hover:bg-gray-100 hover:bg-opacity-25 py-4 h-full w-full bg-transparent flex flex-col items-center justify-between border-l border-t border-solid border-gray-400">
            <img className='object-contain w-48 h-48' src={tokens[Math.floor(Math.random() * 6) + 1]} alt='' />
            <div className="lg:px-4 sm:flex sm:flex-col items-center justify-center">
                <div className="px-2 ml-2 font-bold text-xl text-white mb-2">Project Hidden</div>
                <p className="text-white text-base w-full justify-center text-center">
                {jokes[Math.floor(Math.random() * 9) + 1]}
            </p>
            </div>
            <div className="w-full px-6 pt-4 pb-2 flex flex-wrap gap-2 justify-left">
                <Tag words={"Joke"}/>
        </div>
        </div>
      )
    )
}

export default ProjectCard
import {useState, useEffect, useRef, useMemo} from 'react'
import InputField from '../components/InputField';
import '../styles.css';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import BottomMatter from '../components/BottomMatter';
import Tag from '../components/Tag';
import { ToastContainer } from 'react-toastify';
import ProjectCard from '../components/ProjectCard';
import FilterDropdown from '../components/FilterDropdown';
import HeLPLogo from '../resources/HeLPLogo.png';
import MSOEPLUM from '../resources/MSOEPLUM.png';
import QUT from '../resources/qutlogo.png';
import Bennett from '../resources/Bennett.png';
import Sitede from '../resources/sitede.png';
import QWildlife from  '../resources/QWildlife.png';
import KMER from '../resources/KMER.png';
import DART from '../resources/DART.png';
import Injury from '../resources/INJURY.png';
import ASX from '../resources/ASXstocks.png';
import ToolsLib from '../resources/ToolsLib.png';
import Coral from '../resources/Coral.png';
import Virus from '../resources/Virus.png';
import Dissolve from '../resources/Dissolve.png';
import Brain from '../resources/Brain.png';
import Sinking from '../resources/Sinking.png';
import Python from '../resources/IntroPython.png';
import Routes from '../resources/RouteOptimisation.png';
import Vanilla from '../resources/Vanilla.png';
import Billboard from '../resources/Billboard.png';
import SQL from '../resources/SQL.png';
import Tom from '../resources/Tom.png';
import Cyber from '../resources/Cyber.png';
import { createRoot } from 'react-dom/client';


export default function Projects() {

  const technology = useMemo(()=>[
    { id: 1, name: "C / C++" },
    { id: 2, name: "Python" },
    { id: 3, name: "SQL" },
    { id: 4, name: "R" },
    { id: 5, name: "CUDA"},
    { id: 6, name: "C#" },
    { id: 7, name: "Java" },
    { id: 8, name: "MATLAB" },
    { id: 9, name: "HTML / CSS" },
    { id: 10, name: "JavaScript" },
    { id: 11, name: "React.js" },
    { id: 12, name: "AWS" },
    { id: 13, name: "Node.js" },
    { id: 14, name: "Docker" },
    { id: 15, name: "Qt" },
    { id: 16, name: "ArcGIS Pro" },
    { id: 17, name: "ArcPy" },
    { id: 18, name: "ArcGIS Dashboards" },
    { id: 19, name: "ESRI Survey123" },
    { id: 20, name: "12D Synergy" },
    { id: 21, name: "Cloud Compare" },
    { id: 22, name: "QGIS" },
    { id: 23, name: "Cyclone 3DR" },
    { id: 24, name: "Git" },
    { id: 25, name: "Wireshark" },
    { id: 26, name: "CISCO Packet Tracer" },
    { id: 27, name: "MariaDB" },
    { id: 28, name: "PostgreSQL" },
    { id: 29, name: "Sqlite3" },
    { id: 30, name: "CrypTool" },
    { id: 31, name: "Unity" },
    { id: 32, name: "FME" },
    { id: 33, name: "PowerBI" },
    { id: 34, name: "Firebase" },
    { id: 35, name: "ArcGIS SDK JS"}
  ],[]);

  const projectTags = useMemo(()=>[
    [technology[1].name, technology[16].name, technology[15].name, technology[23].name],
    [technology[0].name, technology[4].name, technology[23].name, technology[20].name],
    [technology[8].name, technology[9].name, technology[10].name, technology[12].name, technology[15].name, technology[23].name, technology[33].name, technology[34].name],
    [technology[8].name, technology[9].name, technology[10].name, technology[12].name, technology[23].name],
    [technology[1].name, technology[23].name],
    [technology[8].name, technology[9].name, technology[10].name, technology[12].name, technology[23].name, technology[34].name],
    [technology[8].name, technology[9].name, technology[10].name, technology[12].name, technology[23].name, technology[34].name],
    [technology[17].name, technology[18].name, technology[27].name],
    [technology[0].name, technology[4].name],
    [technology[30].name, technology[5].name, technology[23].name],
    [technology[3].name, technology[23].name],
    [technology[3].name],
    [technology[5].name],
    [technology[3].name],
    [technology[3].name],
    [technology[3].name, technology[23].name],
    [technology[3].name],
    [technology[6].name, technology[23].name],
    [technology[5].name],
    [technology[24].name, technology[25].name],
    [technology[7].name],
    [technology[0].name],
    [technology[2].name],
    [technology[1].name, technology[26].name],
    [technology[8].name, technology[9].name, technology[23].name],
  ],[technology]);

  const projectIDS = useMemo(()=>[
    [2,17,16, 24], [1, 5, 24, 21], [9, 10, 11, 12, 13, 16, 24, 34, 35], [9, 10, 11, 13, 24], [2,24],
    [9, 10, 11, 13, 24, 35], [9, 10, 11, 13, 24, 35], [18, 19, 28], [1,5], [31,6,24], [4, 24],
    [4], [6], [4], [4], [4, 24], [4], [7, 24], [6], [25, 26], [8], [1], [3], [2, 27], [9, 10, 24],
  ],[]);

  const projectNames = useMemo(()=>[
    "The HeLP Algorithm", "MSOE-PLUM", "Services Availability QLD", "Survey Docket App", "Pixel Scan",
    "Cadastral Searcher", "ADAC XML Renderer", "QWildlife Dashboard", "K-mer Signatures",
    "D.A.R.T. Platform", "Injury Modelling in AFL", "ASX Stocks Predictions", "Tools Library System",
    "Coral Health Modelling", "COVID-19 vs UV Index", "Disaster Survival Modelling", "Dissolution Rate Modelling",
    "Billboard Management", "Route Optimisation System", "Cyber Threat Analysis", "MRI Diffusion Tensor Modelling",
    "Tom and Jerry", "Intro to SQL", "Intro to Python", "Intro to Web Dev"
  ],[]);

  const projectLogos = useMemo(()=>[
    HeLPLogo, MSOEPLUM, QUT, Bennett, Sitede, Sitede, Sitede, QWildlife, KMER, DART, Injury, ASX, ToolsLib, Coral, Virus, Sinking, Dissolve,
    Billboard, Routes, Cyber, Brain, Tom, SQL, Python, Vanilla],[]);

  const projectDescriptions = useMemo(()=>[
    "Hierarchical Land Parcel Aggregation algorithm is a novel approach to automated statistical regionalisation. I have created this algorithm to automatically repartition parcel-based spatial frameworks. The algorithm operates in O (n log n) time. The methodology has been published in the International Journal of GIS in 2023.",
    "Implementation of the Maximum Sum of Evidence and Pose Look-Up Method algorithms for detection and pose estimation of STL models in point cloud environments.",
    "Research project completed under the QUT First Byte scheme. Services availability is a web platform for Queenslanders to assess their availability to critical services such as hospitals, grocery stores, etc. The app also allows the users to alter their routes with editing tools to emulate floods, other natural disasters or road closures.",
    "A digital platform for surveyor site instruction forms.",
    "A Python application to compare pixel-wise differences between survey plans and leases.",
    "A web application that downloads a segment of the Queensland or New South Wales Cadastre and outputs it as a 12DXML file.",
    "A web application that allows the user to upload and visualise ADAC XML files as Feature Layers on a Map.",
    "As part of the QWildlife platform I have developed an ArcGIS Dashboard for project management.",
    "A high-performance parallel file reader to process K-mer protein signatures.",
    "Driver Augmented Run Training is a desktop application that reviewed and simulated a train driver's approach to a stop signal based off telemetric data.",
    "Project completed in partnership with AIS and West Coast Eagles. Prediction of lower limb injuries using generalised linear mixture models, random forests, Kaplan-Meier and the Cox proportional hazards models.",
    "Prediction of ASX Bank of Queensland stock price using autoregressive integrated moving average models (ARIMA).",
    "An administrative system for a tools library that manages the stock, renting and users. Conducted computational complexity analysis and provided implementation of complex data structures and search algorithms.",
    "Modelling of the distribution and ratio of coverage of coral in Western Australia with generalised linear mixed models (GLMM).",
    "Conducted statistical Fourier analysis to determine temporal correlation between UV radiation and the spread of COVID-19. Performed statistical tests to check spatial correlation. Utilised statistical techniques such as linear regression, and ANCOVA.",
    "Using the famous Titanic data set from Kaggle, I have made a random forest prediciton model to analyse the chances of disaster survival.",
    "Created and conducted an experiment to model the rate of dissolution of various medicine in water, followed by exploratory data analysis and regression modelling. ",
    "Developed a client-server application in Java that manages users and allows them to create and schedule billboards.",
    "Developed a console application that solves the travelling salesman problem in 4 levels of complexity.",
    "Conducted network analysis for potential cyber threats and mitigation strategies.",
    "A MATLAB script that conducts eigenface and diffusion modelling to segment and visualise MRI brain scan data.",
    "Created a Pacman-style Tom & Jerry game on a microprocessor.",
    "My first ever project with SQL where I made a database for a fictional police force that manages equipment, staff hierarchy, shifts, criminals and cases.",
    "My first ever Python project that utilised Turtle graphics to draw car logos, and do web scraping for the top 10 songs on ARIA music charts.",
    "My first ever web development project with vanilla JS and HTML / CSS where I made a front-end web page for a fictional grocery store.",
  ],[]);

  const [flippedStates, setFlippedStates] = useState(Array.from({ length: 25 }).fill(true));
  const [currentFilters, setCurrentFilters] = useState([]);
  const filterDivRef = useRef(null);
  // const parallaxRef = useRef(null);

  useEffect(() => {

    const handleClick = (event) => {
      const filterId = parseInt(event.currentTarget.id);
      const updatedFilters = currentFilters.filter((id) => id !== filterId);
      setCurrentFilters(updatedFilters);

      const tagElement = event.currentTarget;
      const parentDiv = tagElement.parentNode;
      parentDiv.removeChild(tagElement);
    };

    const parentDiv = filterDivRef.current;

    parentDiv.innerHTML = ""; // Clear the parent div before rendering new divs
    
    currentFilters.forEach((element) => {
      const tagProps = {
        id: technology[element - 1].id,
        words: technology[element - 1].name,
        clickFunc: handleClick,
      };
    
      const tagElement = <Tag {...tagProps} />;
      const container = document.createElement('div');
      parentDiv.appendChild(container);
      createRoot(container).render(tagElement);
    });

    if(currentFilters.length === 0){
      setFlippedStates(Array.from({ length: 25 }).fill(true));
    }else{
      setFlippedStates((prevState) => {
        const newFlippedStates = prevState.map((_, i) => {
          const hasCommonElement = projectIDS[i].some((element) => {
            return currentFilters.includes(element);
          });
          return hasCommonElement;
        });
        return newFlippedStates;
      });
    }

  }, [currentFilters, technology, projectIDS]);


  return (
    <div className='absolute flex flex-col w-screen h-screen bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/Projects$"}/>
      <div className='flex flex-col sm:flex-row relative justify-start top-0 z-60 h-fit md:h-24'>
        <FilterDropdown options={technology} filters={currentFilters} setFilters={setCurrentFilters}/>
        <div ref={filterDivRef} className="ml-4 flex relative gap-4 items-center justify-start mt-4 bg-transparent z-60"/>
      </div>
      <div className="mt-24 mb-24 md:mt-20 md:mb-24 lg:mt-16 lg:mb-16 xl:mt-12 xl:mb-12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-10 gap-x-[40px] place-items-center w-screen lg:px-24 xl:px-60 md:px-16 px-10">
        {(() => {
            const cards = [];
            for (let i = 0; i < 25; i++) {
              cards.push(
                <ProjectCard
                  key={i}
                  name={projectNames[i]}
                  isFlipped={flippedStates[i]}
                  tagsList={projectTags[i]}
                  image={projectLogos[i]}
                  description={projectDescriptions[i]}
                />
              );
            }
          return cards;
          })()}
      </div>

      <BottomMatter arrowNeeded={true}/>
      <ToastContainer containerId={"projects-container"}/>
    </div>
  )
}

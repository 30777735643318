import JiggerModel from './JiggerModel';
import { Ground } from './Ground';
import { OrbitControls, PerspectiveCamera} from "@react-three/drei";
import {EffectComposer, Bloom, ChromaticAberration,} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { Boxes } from "./Boxes";
import Name from './Name';

export default function Show() {
  return (
    <>
    {/* autoRotate autoRotateSpeed={1.5} */}
        <OrbitControls target={[-2, -2, -1]} maxPolarAngle={1.45} enablePan={false} enableZoom={false} autoRotate={true} autoRotateSpeed={3.25}/>
        <PerspectiveCamera makeDefault fov={90} position={[8,10,11]} />
        <color args={[0, 0, 0]} attach="background" />

        <spotLight
            color={[1, 0.7, 0.5]}
            intensity={1.5}
            angle={1.5}
            penumbra={0.5}
            position={[4,10, 3]}
            castShadow
            shadow-bias={-0.0004}
            />

        <spotLight
            color={[0.14, 0.3, 1]}
            intensity={2}
            angle={1.8}
            penumbra={0.5}
            position={[-5, 10, -1]}
            castShadow
            shadow-bias={-0.0002}
        />
      <Name text={'Phil Juricev'} position={[-7,8,8]} colour={'cyan'} size={1.5} thickness={0.5}/>
      <Name text={'Statistician & Software Developer'} position={[-7,7,8]} colour={'yellow'} size={0.6} thickness={0.25}/>
      <JiggerModel position={[-1.5, 0, 5]} scale={[5, 5, 5]} />
      <Boxes/>
      <Ground />

      <EffectComposer>
        {/* <DepthOfField focusDistance={0.0035} focalLength={0.01} bokehScale={3} height={480} /> */}
        <Bloom
          blendFunction={BlendFunction.ADD}
          intensity={0.25} // The bloom intensity.
          width={300} // render width
          height={300} // render height
          kernelSize={4} // blur kernel size
          luminanceThreshold={0.08} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
        />
        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={[0.0005, 0.0012]} // color offset
        />
      </EffectComposer>
    </>
  )
}

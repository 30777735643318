import InputField from '../components/InputField';
import '../styles.css';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import BottomMatter from '../components/BottomMatter';
import { ToastContainer } from 'react-toastify';
import PublicationContainer from '../components/PublicationContainer';
import Book from '../resources/book.png';
import Quill from '../resources/quill.png';
import QLD from '../resources/qld.png';

const citationA=`
@article{HELP,
  author = {Juricev-Martincev,Filip and Giuffrida,Bernadette and Thompson,Mery and White,Gentry},
  title = {A novel hierarchical aggregation algorithm for optimal repartitioning of statistical regions},
  journal = {International Journal of Geographical Information Science},
  year = {2023},
  doi = {10.1080/13658816.2023.2204347},
  }
`
export default function Publications() {
  return (
    <div className='absolute flex flex-col w-screen h-screen bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/Publications$"}/>
      <div className='w-full h-fit bg-black flex flex-col'>
        <div className='w-full h-fit px-4 py-10 flex justify-center items-center mt-4'>
          <PublicationContainer title={"A novel hierarchical aggregation algorithm for optimal repartitioning of statistical regions"}
          journal={"International Journal of Geographical Information Sciences"} year={"2023"} link={"https://www.tandfonline.com/doi/full/10.1080/13658816.2023.2204347"} citation={citationA}/> 
        </div>
        <div className='w-full h-full grid grid-cols-1 place-items-center md:grid-cols-3 mb-10'>
          <img className='w-64 h-64' src={Book} alt=""/>
          <img className='w-64 h-64' src={Quill} alt=""/>
          <img className='w-64 h-64' src={QLD} alt=""/>
        </div>
      </div>
      <BottomMatter arrowNeeded={false}/>
      <ToastContainer/>
    </div>
  )
}

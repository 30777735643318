function handleClick(link) {
    const win = window.open(link, '_blank');
    win.focus();
  }

function handleCite(citation){
    const filename = "HeLP_Citation.txt";

    const blob = new Blob([citation], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    }

function PublicationContainer({title, journal, year, link, citation}) {

  return (
  <div className="max-w-sm sm:max-w-xl p-6 mt-12 border rounded-lg shadow bg-gray-800 border-gray-700">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">{title}</h5>
      <p className="mb-3 font-normal text-gray-400">{journal}, {year}</p>
      <div className='w-full sm:w-1/2 h-auto grid grid-cols-2 gap-4'>
        <a onClick={()=>handleClick(link)} className="hover:cursor-pointer inline-flex items-center justify-between px-3 py-2 text-sm font-medium text-center text-white rounded-lg  focus:ring-4 focus:outline-none  bg-green-600 hover:bg-green-700 focus:ring-green-800">
            Read article
            <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </a>
        <a onClick={()=>handleCite(citation)} className="hover:cursor-pointer inline-flex items-center justify-between px-3 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
            Cite paper
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2 -mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </a>
      </div>
  </div>
  )
}

export default PublicationContainer
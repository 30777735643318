import '../styles.css';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputField from '../components/InputField';
import MainScene from '../components/MainScene';
import BottomMatter from '../components/BottomMatter';

export default function Home() {

  return (
    <div className='absolute flex flex-col w-screen h-full bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-transparent border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"~$"}/>
      <MainScene/>
      <BottomMatter arrowNeeded={false}/>
      <ToastContainer/>
    </div>
  )
}

import InputField from '../components/InputField';
import BottomMatter from '../components/BottomMatter';
import { ToastContainer } from 'react-toastify';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import Profile from '../resources/profilePicture.jpg';
import {AiOutlineLinkedin} from 'react-icons/ai';
import Toy from '../resources/toy.png';


export default function About() {

  function handleClick(link) {
    const win = window.open(link, '_blank');
    win.focus();
  }

  return (
    <div className='absolute flex flex-col w-screen h-screen z-50 overflow-y-auto bg-black overflow-x-hidden'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/About$"}/>

      <div className='w-screen h-full grid grid-cols-1 lg:grid-cols-2 place-items-center'>

        <div className='w-full h-fit flex flex-col justify-center items-start ml-24 mt-32 sm:mt-10'>
          <div className="flex relative items-center space-x-4 px-4 py-4">
            <img className="w-20 h-20 rounded-full" src={Profile} alt=""/>
            <span className="top-4 left-14 absolute w-5 h-5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
            <div className="text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white">
              <div>Phil Juricev</div>
              <div className="text-sm md:text-lg lg:text-xl xl:text-2xl tex-white">Statistician / Software Developer</div>
            </div>
          </div>

          <div className='w-full h-fit px-10 py-10 mt-10 border-l border-white'>
            <p className='text-white font-base text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl space-x-4 md:space-x-10 mb-10 mr-10 leading-10'>
            Hi! My name is Phil, an Australian-based statistician and software developer. 
            I like nothing more than probability and geography, so it makes sense that
            my work and research revolves around spatial data.
            </p>
            <p className='text-white font-base text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl space-x-4 md:space-x-10 mr-10 leading-10'>
              My favourite things to work on are geostatistical models, GIS analysis, 
              high-performance computing and a bit of web development on the side.
              When I'm not working I am either out playing sports or being overly competitive in board games.
            </p>
          </div>
        </div>

        <div className='w-full h-full sm:w-1/2 sm:h-2/3 sm:mb-32 sm:border-b sm:border-r sm:border-white flex flex-col justify-center items-start'>
          <div className='w-full h-fit flex justify-center items-center py-2'>
            <h1 className='text-white font-bold text-2xl md:text-3xl xl:text-5xl h-fit'>Let's connect!</h1>
          </div>
          <div onClick={()=>handleClick("https://www.linkedin.com/in/philjuricev/")} className='hover:cursor-pointer w-full h-fit flex flex-row items-center justify-center gap-4'>
            <AiOutlineLinkedin className='ml-4 w-12 h-12 text-blue-600'/><span className='text-bold text-white text-xl sm:text-2xl lg:text-3xl xl:text-4xl'>@philjuricev</span>
          </div>
          <div className='w-full h-fit flex items-center justify-center mb-10'>
            <img className='w-48 h-48 sm:w-96 sm:h-96' src={Toy} alt=""/>
          </div>
        </div>
      </div>


      <BottomMatter arrowNeeded={false}/>
      <ToastContainer/>
    </div>
  )
}

import { useState } from 'react';
import { toast} from 'react-toastify';

const tooManyFilters = () => toast.warning('Filter already exists or too many filters added!', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    containerId: "projects-container"
});

const FilterDropdown = ({options, filters, setFilters}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const items = options;

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().startsWith(search.toLowerCase())
  );

  const handleClick = (e) =>{
    const id = e.target.value;
    if (filters.length < 5 && !filters.includes(options[id-1].id)) {
        setFilters((prevFilters) => [...prevFilters, options[id-1].id]);
      } else {
        tooManyFilters();
      }
  }

  return (
    <div className="w-3/4 sm:w-1/2 text-green-400 flex flex-col lg:w-1/3 xl:w-1/6 h-fit z-40 ml-4 mb-2 bg-transparent items-start justify-left relative overflow-hidden mt-28 sm:mt-16 lg:mt-10">
      <input
        onClick={() => setOpen(!open)}
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Filter by technology..."
        className="bg-black text-green-400 py-3 px-4 w-full rounded shadow border-2 border-green-500"
      />

      {open && (
        <ul
          onClick={() => setOpen(!open)}
          className="w-full overflow-y-auto max-h-52 transition-opacity duration-300 ease-out transform translate-y-0 opacity-100"
          style={{
            transition: 'opacity 0.3s ease-out, transform 0.3s ease-out',
            opacity: '1',
            transform: 'translate(0)',
          }}
        >
        {filteredItems.map((item) => (
            <li
                key={item.id}
                onClick={(e)=>handleClick(e)}
                value={item.id}
                className="w-full text-green-300 mt-2 p-4 h-fit bg-black border-2 border-green-500 hover:cursor-pointer hover:bg-green-500 hover:text-black"
            >
            {item.name}
            </li>
        ))}
        </ul>
      )}
    </div>
  );
};

export default FilterDropdown;

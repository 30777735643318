function Arrow() {
  return (
    <div className="hidden sm:flex sm:items-center sm:justify-center sm:absolute sm:bottom-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 h-12 w-12 z-50">
      <div className="absolute bottom-0">
        <div className="animate-bounce">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Arrow
import React, {useState, useCallback} from 'react'
import '../styles.css';
import {useNavigate } from 'react-router-dom';

export default function InputField({currentDirectory}) {

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState('');

    const helpOutput = "Use this terminal to navigate the website. Press l to list all directories. Press cd to navigate to another location.\r\n"
    const directories = ['Home', 'Education', 'Experience','Publications', 'Projects','About']
    const invalidCommand = "Not a recognised internal or external command"

    const handleEnter = (e) =>{

        if (e.keyCode === 9 && inputValue.trim().includes("cd")) { // TAB key auto-complete when user types CD
            e.preventDefault();
            const words = inputValue.trim().split(" ");

            // check last word
            const currentWord = words[words.length - 1];

            // regex it
            const regex = new RegExp('^' + currentWord, 'i');

            //filter
            const matchingDirectories = directories.filter(d => d.match(regex));

            if (matchingDirectories.length === 1) {
              const completedValue = inputValue.replace(currentWord, matchingDirectories[0]);
              setInputValue(completedValue);
            }
            else{
                setOutputValue(matchingDirectories.join("\t"));
            }
          }

        if(e.key === 'Enter'){

            const regex = /^cd\s+[\w~.//]+$/;

            if(inputValue.trim() === "help"){
                setOutputValue(helpOutput)
                setInputValue('')
            }
            else if(inputValue.trim() === "l" || inputValue.trim() === "ls"){
                setOutputValue(directories.join("\t"))
                setInputValue('')
            }
            else if(new RegExp(regex).test(inputValue.trim())){

                var dir = inputValue.trim().split(" ")[1]

                if(directories.includes(dir) || dir === "~" || dir === "../"){
                    if(dir === "~" || dir === "Home" || dir === "../"){
                        setOutputValue('')
                        setInputValue('')
                        navigate("/")
                    }
                    else{
                        setOutputValue('')
                        setInputValue('')
                        navigate('/'+dir.toLowerCase())
                    }
                }
                else{
                    setOutputValue('Not a valid directory\r\n')
                    setInputValue('')
                }

            }
            else{
                setOutputValue(invalidCommand)
                setInputValue('')
            }
            setInputValue('');
        }
    }

    const handleInputChange = useCallback((e) => {
        const value = e.target.value;

        setInputValue(value);

      }, [setInputValue]);


  return (
    <div className='relative flex flex-wrap w-screen h-2 sm:h-4 md:h-6 lg:h-10 xl:h-16 z-50 align-middle bg-black'>
        <label className='flex text-green-600 ml-2 mt-3 flex-shrink-0 font-mono z-50'>user@phils-portfolio:{currentDirectory}</label>
        <input className='mt-1 h-10 bg-black text-green-600 font-mono flex-grow focus:outline-none z-50 ml-2 border-b border-solid border-green-600 px-2' type="text" value={inputValue} onKeyDown={handleEnter} onChange={handleInputChange}/>
        <div className='mt-1 ml-2 font-mono text-xs sm:text-sm md:text-base lg:text-lg text-green-600 bg-transparent w-screen h-auto mb-2 z-50' >{outputValue}</div>
    </div>
  )
}

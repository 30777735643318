import {AiOutlineCloseCircle} from 'react-icons/ai';
import {VscTerminalCmd} from 'react-icons/vsc';
import BottomMatter from '../components/BottomMatter';
import InputField from '../components/InputField';

export default function NoPage() {
  return (
    <div className='absolute flex flex-col w-screen h-screen bg-black z-50 overflow-y-auto'>
      <div className='w-screen h-14 relative flex justify-between items-center bg-black border-b border-solid border-green-600 shadow-md z-50'>
        <VscTerminalCmd className='h-10 w-10 text-lg font-bold text-green-600 relative ml-4 z-50'/>
        <AiOutlineCloseCircle className='h-10 w-10 text-lg font-bold text-green-600 relative mr-4 rounded-full z-10'/>
      </div>
      <InputField currentDirectory={"/unknown$"}/>
      <div className='w-full h-full flex justify-center items-center'>
        <h1 className='text-md sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white'>You shouldn't be here!</h1>
      </div>
      <BottomMatter arrowNeeded={false}/>
    </div>
  )
}
